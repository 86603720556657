.visualization_container {
    width: 80%;
    margin: 0 auto;
    background-color: transparent;
  }
  
  .visualization {
    width: 100%;
    margin: 6% 0% 6% 0%;
    box-shadow: var(--shadow);
    display: flex;
    border-radius: 20px;
    transition: all 0.5s ease 0s;
  }
  
  .visualization:hover {
    transform: scale(1.04);
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.36) 0px 10px 15px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .visualization_videocontainer {
    flex-basis: 65%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .visualization_videocontainer > div {
    width: 90%;
    height: 90%;
    border-radius: 20px;
  }
  .visualization_videocontainer > div > img {
    width: 100%;
    height: 100%;
    border: 3px solid var(--clr-primary);
    border-radius: 20px;
  }
  
  .visualization_information {
    flex-basis: 35%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 3% 4% 3%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 10px;
  }
  .visualization_information > h2 {
    margin-top: 1em;
    color: var(--clr-primary);
  }
  .visualization_information > p {
    margin-top: 1em;
    text-align: center;
  }
  .visualization_information > div:nth-child(3) {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-evenly;
  }
  
  .visualization_information > div:nth-child(3) > svg {
    font-size: 3em;
    margin: 2% 3% 2% 3%;
    color: var(--clr-primary);
    transition: all 0.2s ease-in-out;
  }
  
  .visualization_information > div:nth-child(3) > svg:hover {
    font-size: 3.3em;
  }
  
  .visualization_information > div:nth-child(4) {
    margin-top: 0.6em;
    display: flex;
  }
  
  .onbt {
    margin: 0 1vw 0 1vw;
  }
  .btns{
    color: var(--clr-primary);
    border: 2px solid var(--clr-primary);
    border-radius: 5px;
    height: auto;
    cursor: pointer;
    padding: 10px;
    margin-top: 10px;
  }
  .btns:hover{
    color: var(--clr-bg);
    border: 2px solid var(--clr-primary);
    background-color: var(--clr-primary);
  }
  .visualization_information > h3 {
    margin-top: 1.6em;
    color: var(--clr-primary);
  }
  .visualization_information::-webkit-scrollbar {
    display: none;
  }
  
  .allVisualizations{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .visualization {
      border: 2px solid var(--clr-primary);
      flex-direction: column;
    }
  
  @media (max-width: 800px) {
    .visualization {
      flex-direction: column;
      /* height: 100vh; */
    }
    .visualization_videocontainer {
      flex-basis: 40%;
    }
    .visualization_information {
      flex-basis: 60%;
    }
    .allVisualizations{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .visualization_container {
      width: 100%;
      margin: 0 auto;
    }
  }